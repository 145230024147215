exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-company-about-tsx": () => import("./../../../src/pages/company/about.tsx" /* webpackChunkName: "component---src-pages-company-about-tsx" */),
  "component---src-pages-company-partners-tsx": () => import("./../../../src/pages/company/partners.tsx" /* webpackChunkName: "component---src-pages-company-partners-tsx" */),
  "component---src-pages-company-project-tsx": () => import("./../../../src/pages/company/project.tsx" /* webpackChunkName: "component---src-pages-company-project-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-carbon-tsx": () => import("./../../../src/pages/legal/carbon.tsx" /* webpackChunkName: "component---src-pages-legal-carbon-tsx" */),
  "component---src-pages-legal-mentions-tsx": () => import("./../../../src/pages/legal/mentions.tsx" /* webpackChunkName: "component---src-pages-legal-mentions-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-subscription-confirmation-tsx": () => import("./../../../src/pages/subscription-confirmation.tsx" /* webpackChunkName: "component---src-pages-subscription-confirmation-tsx" */),
  "component---src-pages-utils-coming-soon-tsx": () => import("./../../../src/pages/utils/coming-soon.tsx" /* webpackChunkName: "component---src-pages-utils-coming-soon-tsx" */),
  "component---src-pages-windship-offer-tsx": () => import("./../../../src/pages/windship/offer.tsx" /* webpackChunkName: "component---src-pages-windship-offer-tsx" */),
  "component---src-pages-windship-ship-tsx": () => import("./../../../src/pages/windship/ship.tsx" /* webpackChunkName: "component---src-pages-windship-ship-tsx" */)
}

